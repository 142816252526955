import { AbstractControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const NAME = /^[a-zA-Z-.'\s]+$/;
export const CUSTOMER_NAME = /^[a-zA-Z\s]+$/;
export const CUSTOMER_NAME_MIN_LENGTH = 2;
export const CUSTOMER_NAME_MAX_LENGTH = 100;
export const CUSTOMER_COMPANY_NAME = /^[a-zA-Z0-9.,\-/&'\s]+$/;
export const TRACK_NUMBER = /^[a-zA-Z0-9.,\-$']+$/;
export const CUSTOMER_COMPANY_NAME_MIN_LENGTH = 3;
export const CUSTOMER_COMPANY_NAME_MAX_LENGTH = 150;
export const COMPANY_NAME = /^[a-zA-Z0-9#&+()"',.:\-/\s]*$/;
export const NUMBER = /^[0-9]*$/;
export const NUMBER_FLOAT = /^(\d+)?(\.)?(\d{0,2})?$/;
export const PHONE = /^[0-9]{10}$/;
export const ADDRESS = /^[a-zA-Z0-9#&+()"',.;:\-/\s]*$/;
export const PIN = /^[0-9]{6}$/;
export const VIN = /^[A-HJ-NPR-Z0-9]{17}$/;
export const GST = /^[A-Z0-9]+$/;
export const CITY = /^[a-zA-Z0-9()"',.;:\-/\s]+$/;
export const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export class AbstractForm
{
    public moneyFormat = '1.23';
    public minLength = 2;
    public maxLength = 255;
    public nameMinLength = 3;
    public nameMaxLength = 100;
    public phoneLength = 10;
    public minAddressLength = 10;
    public maxAddressLength = 110;
    public pinLength = 6;
    public cityMinLength = 2;
    public cityMaxLength = 50;
    public gstLength = 15;
    public minEmail = 5;
    public maxEmail = 50;
    public gstPattern = '^[A-Z0-9]+$';
    public emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    public errorMsg = {
        general: 'Please enter a valid %path%!',
        required: 'This field is required!',
        email: 'Please enter a valid email address.',
        min: 'Minimum required %path% is %value%!',
        max: 'Maximum %path% can be %value%!',
        minLength: 'The %path% should be at least %value% characters!',
        maxLength: 'The %path% should be no more than %value% characters!',
        pattern: 'The %path% must be in the correct format, like: "%value%"',
        pinInvalid: 'Wrong PIN.',
        gstPattern: 'Please enter valid GST.',
        gstInvalid: 'Wrong GST.',
        gstNotUnique: 'GST number is not unique.',
        gstNotBelongState: 'GST number does not match the state.',
        pinNotBelongState: 'The PIN should be valid and match the state from your address and GST number',
        namePattern: 'Please, enter only Latin characters, spaces, \' or - symbols.',
    };

    protected form: UntypedFormGroup;
    protected submitted: boolean;

    public getForm = (): UntypedFormGroup => this.form;

    public getPattern = (
        pattern: 'EMAIL' | 'NAME' | 'NUMBER' | 'PHONE' | 'ADDRESS' | 'PIN' | 'VIN' | 'GST' | 'CITY' | RegExp
    ): RegExp | string=> {
        switch (pattern) {
            case 'CITY':
                return CITY;
            case 'ADDRESS':
                return ADDRESS;
            case 'EMAIL':
                return EMAIL;
            case 'GST':
                return GST;
            case 'NAME':
                return String(NAME).replace(/\//g, '');
            case 'NUMBER':
                return NUMBER;
            case 'PHONE':
                return PHONE;
            case 'PIN':
                return PIN;
            case 'VIN':
                return VIN;
            default:
                return pattern;
        }
    };

    public markSubmitted = (): void => {
        this.submitted = true;
    };

    public clearSubmitted = (): void => {
        this.submitted = false;
    };

    public getErrorMsg = (field: string, path?: string, value?: number | string): string => {
        let result = this.errorMsg[field];
        if (value !== undefined) {
            result = result.replace(/%value%/, String(value));
        }
        if (path !== undefined) {
            result = result.replace(/%path%/, path);
        }
        return result;
    };

    public isSubmitted = (): boolean => this.submitted;

    public getMoneyValue(valueKey: string): number
    {
        if (!this.form.value[valueKey]) {
            throw new Error('Value key: ' + valueKey + ' doesn\'t present in form');
        }

        return this.form.value[valueKey] * 100;
    }

    public isInvalid(key: string): boolean {
        return this.form.get(key).invalid && (this.form.get(key).dirty || this.submitted);
    }

    public isValid(key: string): boolean {
        return this.form.get(key).valid && (this.form.get(key).dirty || this.submitted);
    }
}

export const controlToUpperCase: ValidatorFn = (control: AbstractControl) => {
    if (control && control.value && /[a-z]/.test(control.value)) {
        control.setValue(control.value.toUpperCase());
    }

    return null;
};

export const removeSpaces: ValidatorFn = (control: AbstractControl) => {
    setTimeout(() => {
        if (control && control.value) {
            if (!control.value.replace(/\s/g, '').length) {
                control.setValue('');
            }

            if (control.value.includes('  ')) {
                control.setValue(control.value.replace(/\s+/g, ' '));
            }
        }
    });

    return null;
};

export const normalizePin: ValidatorFn = (control: AbstractControl) => {
    if (control?.value) {
        if (!String(control.value).replace(/^0+/, '').length) {
            control.setValue('');
        }
    }

    return null;
};

export const normalizeNumbersInteger: ValidatorFn = (control: AbstractControl) => {
    if (control?.value) {
        if (!Number.isInteger(control.value)) {
            control.setValue(Math.round(control.value));
        }
    }

    return null;
};

export const customerCompanyNameValidator: ValidatorFn = (control: AbstractControl) => {
    let error = null;

    const value = control?.value;

    if (value) {
        const actualLength = value.length;
        const isPatternValid = new RegExp(CUSTOMER_COMPANY_NAME).test(value);
        const valueWithNumbersAndLetters = value.replace(/[^a-zA-Z0-9]/g, '');

        if (
            actualLength < CUSTOMER_COMPANY_NAME_MIN_LENGTH ||
            !isPatternValid ||
            valueWithNumbersAndLetters?.length < 2
        ) {
            error = { wrongCompanyName: true };
        }
    }

    return error;
};

export const trimSpaceFormControl = (
    formGroup: FormGroup,
    controls: string[]
): void => {
    controls.map((control) => {
        const value = formGroup.get(control)?.value;
        if (value && typeof value === 'string') {
            formGroup.patchValue({
                [control]: value.trim(),
            });
        }
    });
};

export const stepValidator = (stepValue: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => control.value % stepValue !== 0
        ? {step: {currentValue: control.value, requiredStep: stepValue}}
        : null;
